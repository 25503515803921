exports.components = {
  "component---blog-01-salesforce-hackathon-index-mdx": () => import("./../../../blog/01-salesforce-hackathon/index.mdx" /* webpackChunkName: "component---blog-01-salesforce-hackathon-index-mdx" */),
  "component---blog-02-fresh-start-index-mdx": () => import("./../../../blog/02-fresh-start/index.mdx" /* webpackChunkName: "component---blog-02-fresh-start-index-mdx" */),
  "component---blog-03-links-1-index-mdx": () => import("./../../../blog/03-links-1/index.mdx" /* webpackChunkName: "component---blog-03-links-1-index-mdx" */),
  "component---blog-04-my-favorite-software-in-2023-index-mdx": () => import("./../../../blog/04-my-favorite-software-in-2023/index.mdx" /* webpackChunkName: "component---blog-04-my-favorite-software-in-2023-index-mdx" */),
  "component---blog-05-beyond-read-it-later-apps-index-mdx": () => import("./../../../blog/05-beyond-read-it-later-apps/index.mdx" /* webpackChunkName: "component---blog-05-beyond-read-it-later-apps-index-mdx" */),
  "component---blog-06-how-to-craft-interview-feedback-index-mdx": () => import("./../../../blog/06-how-to-craft-interview-feedback/index.mdx" /* webpackChunkName: "component---blog-06-how-to-craft-interview-feedback-index-mdx" */),
  "component---blog-07-my-favorite-software-2024-edition-index-mdx": () => import("./../../../blog/07-my-favorite-software-2024-edition/index.mdx" /* webpackChunkName: "component---blog-07-my-favorite-software-2024-edition-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-01-salesforce-hackathon-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/01-salesforce-hackathon/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-01-salesforce-hackathon-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-02-fresh-start-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/02-fresh-start/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-02-fresh-start-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-03-links-1-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/03-links-1/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-03-links-1-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-04-my-favorite-software-in-2023-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/04-my-favorite-software-in-2023/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-04-my-favorite-software-in-2023-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-05-beyond-read-it-later-apps-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/05-beyond-read-it-later-apps/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-05-beyond-read-it-later-apps-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-06-how-to-craft-interview-feedback-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/06-how-to-craft-interview-feedback/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-06-how-to-craft-interview-feedback-index-mdx" */),
  "component---src-layouts-blog-post-tsx-content-file-path-blog-07-my-favorite-software-2024-edition-index-mdx": () => import("./../../../src/layouts/BlogPost.tsx?__contentFilePath=/vercel/path0/blog/07-my-favorite-software-2024-edition/index.mdx" /* webpackChunkName: "component---src-layouts-blog-post-tsx-content-file-path-blog-07-my-favorite-software-2024-edition-index-mdx" */),
  "component---src-layouts-blog-tsx": () => import("./../../../src/layouts/Blog.tsx" /* webpackChunkName: "component---src-layouts-blog-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

